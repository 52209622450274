<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M9.34531 2.77105C9.95469 2.89293 10.3508 3.4859 10.2289 4.09527L10.175 4.36246C10.0508 4.98824 9.82109 5.58355 9.5 6.12496H12.875C13.4961 6.12496 14 6.62887 14 7.24996C14 7.84293 13.543 8.32809 12.9617 8.37262C13.1422 8.57184 13.25 8.83668 13.25 9.12496C13.25 9.6734 12.8562 10.1304 12.3383 10.2289C12.4414 10.3976 12.5 10.5992 12.5 10.8125C12.5 11.3117 12.1742 11.7359 11.7242 11.8812C11.7406 11.9586 11.75 12.0406 11.75 12.125C11.75 12.7461 11.2461 13.25 10.625 13.25H8.90234C8.45703 13.25 8.02344 13.1187 7.65312 12.8726L6.75078 12.2703C6.125 11.8531 5.75 11.15 5.75 10.3976V9.49996V8.37496V7.79137C5.75 7.10699 6.06172 6.46246 6.59375 6.03355L6.76719 5.89527C7.38828 5.3984 7.8125 4.69996 7.96719 3.92184L8.02109 3.65465C8.14297 3.04527 8.73594 2.64918 9.34531 2.77105ZM2.75 6.49996H4.25C4.66484 6.49996 5 6.83512 5 7.24996V12.5C5 12.9148 4.66484 13.25 4.25 13.25H2.75C2.33516 13.25 2 12.9148 2 12.5V7.24996C2 6.83512 2.33516 6.49996 2.75 6.49996Z" fill="#828282"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M6.68652 6.68652C8.50879 4.86425 11.4531 4.85546 13.2871 6.65722L12.0801 7.86132C11.8779 8.06347 11.8193 8.36523 11.9277 8.6289C12.0361 8.89257 12.2939 9.0625 12.5781 9.0625H16.0791H16.3281C16.7178 9.0625 17.0312 8.74902 17.0312 8.35937V4.60937C17.0312 4.32519 16.8613 4.06738 16.5977 3.95898C16.334 3.85058 16.0322 3.90918 15.8301 4.11132L14.6113 5.33007C12.0449 2.79589 7.91113 2.80468 5.35938 5.35937C4.64453 6.07421 4.12891 6.91504 3.8125 7.81445C3.63965 8.30371 3.89746 8.83691 4.38379 9.00976C4.87012 9.18261 5.40625 8.9248 5.5791 8.43847C5.80469 7.7998 6.1709 7.19921 6.68652 6.68652ZM2.96875 11.6406V11.8633V11.8838V15.3906C2.96875 15.6748 3.13867 15.9326 3.40234 16.041C3.66602 16.1494 3.96777 16.0908 4.16992 15.8887L5.38867 14.6699C7.95508 17.2041 12.0889 17.1953 14.6406 14.6406C15.3555 13.9258 15.874 13.085 16.1904 12.1885C16.3633 11.6992 16.1055 11.166 15.6191 10.9932C15.1328 10.8203 14.5967 11.0781 14.4238 11.5644C14.1982 12.2031 13.832 12.8037 13.3164 13.3164C11.4941 15.1387 8.5498 15.1475 6.71582 13.3457L7.91992 12.1387C8.12207 11.9365 8.18066 11.6348 8.07227 11.3711C7.96387 11.1074 7.70605 10.9375 7.42188 10.9375H3.91797H3.89746H3.67188C3.28223 10.9375 2.96875 11.251 2.96875 11.6406Z" fill="#BDBDBD"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M26.625 12.6001C26.2738 12.1784 25.8343 11.8389 25.3375 11.6056C24.8407 11.3724 24.2988 11.251 23.75 11.2501H18.05L18.75 9.46256C19.0412 8.67991 19.1381 7.83831 19.0326 7.00996C18.927 6.1816 18.6221 5.39121 18.144 4.70659C17.6659 4.02197 17.0288 3.46355 16.2874 3.07923C15.5461 2.69492 14.7225 2.49618 13.8875 2.50006C13.647 2.50056 13.4118 2.5704 13.2101 2.70121C13.0083 2.83202 12.8486 3.01824 12.75 3.23756L9.1875 11.2501H6.25C5.25544 11.2501 4.30161 11.6451 3.59835 12.3484C2.89509 13.0517 2.5 14.0055 2.5 15.0001V23.7501C2.5 24.7446 2.89509 25.6984 3.59835 26.4017C4.30161 27.105 5.25544 27.5001 6.25 27.5001H22.1625C23.0398 27.4998 23.8892 27.1919 24.5629 26.6301C25.2366 26.0683 25.6921 25.288 25.85 24.4251L27.4375 15.6751C27.5357 15.1343 27.5138 14.5785 27.3734 14.0472C27.233 13.5158 26.9775 13.0217 26.625 12.6001ZM8.75 25.0001H6.25C5.91848 25.0001 5.60054 24.8684 5.36612 24.6339C5.1317 24.3995 5 24.0816 5 23.7501V15.0001C5 14.6685 5.1317 14.3506 5.36612 14.1162C5.60054 13.8818 5.91848 13.7501 6.25 13.7501H8.75V25.0001ZM25 15.2251L23.4125 23.9751C23.3593 24.2663 23.2044 24.5291 22.9755 24.7168C22.7466 24.9045 22.4585 25.0049 22.1625 25.0001H11.25V12.7626L14.65 5.11256C15 5.21459 15.325 5.38806 15.6046 5.62204C15.8841 5.85601 16.1122 6.14539 16.2742 6.47192C16.4363 6.79845 16.529 7.15504 16.5463 7.51918C16.5637 7.88332 16.5053 8.2471 16.375 8.58756L15.7125 10.3751C15.5713 10.7529 15.5237 11.1592 15.5736 11.5595C15.6235 11.9597 15.7695 12.3419 15.9992 12.6735C16.2288 13.0051 16.5353 13.2761 16.8924 13.4636C17.2495 13.6511 17.6467 13.7494 18.05 13.7501H23.75C23.9336 13.7498 24.1151 13.7899 24.2814 13.8677C24.4478 13.9455 24.595 14.059 24.7125 14.2001C24.8329 14.3392 24.9211 14.5032 24.9708 14.6804C25.0205 14.8576 25.0305 15.0436 25 15.2251Z" fill="#333333"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
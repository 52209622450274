<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path d="M4.28398 12.4309L10.1073 17.8675C10.349 18.0931 10.6681 18.2188 11 18.2188C11.3319 18.2188 11.651 18.0931 11.8927 17.8675L17.716 12.4309C18.6957 11.5189 19.25 10.2395 19.25 8.90207V8.71516C19.25 6.46252 17.6226 4.54182 15.4021 4.17121C13.9326 3.92629 12.4373 4.40647 11.3867 5.45705L11 5.84377L10.6133 5.45705C9.5627 4.40647 8.06738 3.92629 6.59785 4.17121C4.37744 4.54182 2.75 6.46252 2.75 8.71516V8.90207C2.75 10.2395 3.3043 11.5189 4.28398 12.4309Z" fill="#E0E0E0"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M15.8327 3.33341H14.166V2.50008C14.166 2.27907 14.0782 2.06711 13.9219 1.91083C13.7657 1.75455 13.5537 1.66675 13.3327 1.66675C13.1117 1.66675 12.8997 1.75455 12.7434 1.91083C12.5871 2.06711 12.4993 2.27907 12.4993 2.50008V3.33341H7.49935V2.50008C7.49935 2.27907 7.41155 2.06711 7.25527 1.91083C7.09899 1.75455 6.88703 1.66675 6.66602 1.66675C6.445 1.66675 6.23304 1.75455 6.07676 1.91083C5.92048 2.06711 5.83268 2.27907 5.83268 2.50008V3.33341H4.16602C3.50297 3.33341 2.86709 3.59681 2.39825 4.06565C1.92941 4.53449 1.66602 5.17037 1.66602 5.83342V15.8334C1.66602 16.4965 1.92941 17.1323 2.39825 17.6012C2.86709 18.07 3.50297 18.3334 4.16602 18.3334H15.8327C16.4957 18.3334 17.1316 18.07 17.6005 17.6012C18.0693 17.1323 18.3327 16.4965 18.3327 15.8334V5.83342C18.3327 5.17037 18.0693 4.53449 17.6005 4.06565C17.1316 3.59681 16.4957 3.33341 15.8327 3.33341ZM16.666 15.8334C16.666 16.0544 16.5782 16.2664 16.4219 16.4227C16.2657 16.579 16.0537 16.6667 15.8327 16.6667H4.16602C3.945 16.6667 3.73304 16.579 3.57676 16.4227C3.42048 16.2664 3.33268 16.0544 3.33268 15.8334V10.0001H16.666V15.8334ZM16.666 8.33342H3.33268V5.83342C3.33268 5.6124 3.42048 5.40044 3.57676 5.24416C3.73304 5.08788 3.945 5.00008 4.16602 5.00008H5.83268V5.83342C5.83268 6.05443 5.92048 6.26639 6.07676 6.42267C6.23304 6.57895 6.445 6.66675 6.66602 6.66675C6.88703 6.66675 7.09899 6.57895 7.25527 6.42267C7.41155 6.26639 7.49935 6.05443 7.49935 5.83342V5.00008H12.4993V5.83342C12.4993 6.05443 12.5871 6.26639 12.7434 6.42267C12.8997 6.57895 13.1117 6.66675 13.3327 6.66675C13.5537 6.66675 13.7657 6.57895 13.9219 6.42267C14.0782 6.26639 14.166 6.05443 14.166 5.83342V5.00008H15.8327C16.0537 5.00008 16.2657 5.08788 16.4219 5.24416C16.5782 5.40044 16.666 5.6124 16.666 5.83342V8.33342Z" fill="#4F4F4F"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M10 17.5C14.1426 17.5 17.5 14.1426 17.5 10C17.5 5.85742 14.1426 2.5 10 2.5C5.85742 2.5 2.5 5.85742 2.5 10C2.5 14.1426 5.85742 17.5 10 17.5ZM13.3105 8.62305L9.56055 12.373C9.28516 12.6484 8.83984 12.6484 8.56738 12.373L6.69238 10.498C6.41699 10.2227 6.41699 9.77734 6.69238 9.50488C6.96777 9.23242 7.41309 9.22949 7.68555 9.50488L9.0625 10.8818L12.3145 7.62695C12.5898 7.35156 13.0352 7.35156 13.3076 7.62695C13.5801 7.90234 13.583 8.34766 13.3076 8.62012L13.3105 8.62305Z" fill="#BDBDBD"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if(props.fill){
                return props.fill
            }
            
            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M19.1126 11.0625L13.2001 16.9875L10.8876 14.6625C10.6522 14.4271 10.333 14.2949 10.0001 14.2949C9.6672 14.2949 9.34796 14.4271 9.11258 14.6625C8.8772 14.8979 8.74496 15.2171 8.74496 15.55C8.74496 15.7148 8.77743 15.878 8.8405 16.0303C8.90358 16.1826 8.99603 16.321 9.11258 16.4375L12.3126 19.6375C12.4288 19.7547 12.567 19.8477 12.7194 19.9111C12.8717 19.9746 13.0351 20.0072 13.2001 20.0072C13.3651 20.0072 13.5285 19.9746 13.6808 19.9111C13.8331 19.8477 13.9714 19.7547 14.0876 19.6375L20.8876 12.8375C21.0041 12.721 21.0966 12.5826 21.1597 12.4303C21.2227 12.278 21.2552 12.1148 21.2552 11.95C21.2552 11.7852 21.2227 11.622 21.1597 11.4697C21.0966 11.3174 21.0041 11.179 20.8876 11.0625C20.771 10.946 20.6327 10.8535 20.4804 10.7904C20.3281 10.7274 20.1649 10.6949 20.0001 10.6949C19.8353 10.6949 19.672 10.7274 19.5198 10.7904C19.3675 10.8535 19.2291 10.946 19.1126 11.0625ZM15.0001 2.5C13.3586 2.5 11.7331 2.82332 10.2165 3.45151C8.69996 4.07969 7.32197 5.00043 6.16124 6.16117C3.81704 8.50537 2.50008 11.6848 2.50008 15C2.48915 17.8864 3.48857 20.6857 5.32508 22.9125L2.82508 25.4125C2.65163 25.5883 2.53413 25.8115 2.48742 26.054C2.4407 26.2965 2.46685 26.5474 2.56258 26.775C2.6664 26.9999 2.83471 27.1889 3.04614 27.318C3.25756 27.4471 3.50258 27.5104 3.75008 27.5H15.0001C18.3153 27.5 21.4947 26.183 23.8389 23.8388C26.1831 21.4946 27.5001 18.3152 27.5001 15C27.5001 11.6848 26.1831 8.50537 23.8389 6.16117C21.4947 3.81696 18.3153 2.5 15.0001 2.5ZM15.0001 25H6.76258L7.92508 23.8375C8.15789 23.6033 8.28857 23.2865 8.28857 22.9562C8.28857 22.626 8.15789 22.3092 7.92508 22.075C6.28831 20.4401 5.26904 18.2882 5.04094 15.986C4.81284 13.6838 5.39 11.3738 6.67411 9.4494C7.95822 7.52505 9.86982 6.10545 12.0832 5.43247C14.2966 4.75948 16.6749 4.87476 18.8128 5.75864C20.9508 6.64253 22.7161 8.24034 23.8081 10.2799C24.9001 12.3194 25.2512 14.6744 24.8015 16.9438C24.3518 19.2131 23.1292 21.2563 21.342 22.7253C19.5548 24.1943 17.3135 24.9982 15.0001 25Z" fill="#333333"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
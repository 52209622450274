<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path d="M7.76165 0.492132C7.62114 0.191385 7.3242 0 6.9981 0C6.67199 0 6.37771 0.191385 6.23454 0.492132L4.5298 4.10931L0.722631 4.68893C0.404484 4.73814 0.139361 4.9678 0.041265 5.28222C-0.0568306 5.59664 0.0227063 5.94387 0.250712 6.17626L3.0133 8.99509L2.36109 12.9786C2.30807 13.3067 2.44063 13.6403 2.7031 13.8344C2.96557 14.0285 3.31288 14.0531 3.59922 13.8973L7.00075 12.0244L10.4023 13.8973C10.6886 14.0531 11.0359 14.0312 11.2984 13.8344C11.5609 13.6375 11.6934 13.3067 11.6404 12.9786L10.9855 8.99509L13.7481 6.17626C13.9761 5.94387 14.0583 5.59664 13.9576 5.28222C13.8568 4.9678 13.5944 4.73814 13.2762 4.68893L9.46639 4.10931L7.76165 0.492132Z" fill="#BDBDBD"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
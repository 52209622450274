<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M10.3333 3H5.66667C5.11458 3 4.66458 3.45417 4.68542 4.00417C4.68958 4.11458 4.69375 4.225 4.7 4.33333H2.5C2.22292 4.33333 2 4.55625 2 4.83333C2 6.7625 2.69792 8.10417 3.63542 9.01458C4.55833 9.9125 5.68333 10.3646 6.5125 10.5938C7 10.7292 7.33333 11.1354 7.33333 11.5438C7.33333 11.9792 6.97917 12.3333 6.54375 12.3333H6C5.63125 12.3333 5.33333 12.6313 5.33333 13C5.33333 13.3688 5.63125 13.6667 6 13.6667H10C10.3688 13.6667 10.6667 13.3688 10.6667 13C10.6667 12.6313 10.3688 12.3333 10 12.3333H9.45625C9.02083 12.3333 8.66667 11.9792 8.66667 11.5438C8.66667 11.1354 8.99792 10.7271 9.4875 10.5938C10.3188 10.3646 11.4438 9.9125 12.3667 9.01458C13.3021 8.10417 14 6.7625 14 4.83333C14 4.55625 13.7771 4.33333 13.5 4.33333H11.3C11.3063 4.225 11.3104 4.11667 11.3146 4.00417C11.3354 3.45417 10.8854 3 10.3333 3ZM3.01875 5.33333H4.77708C4.96667 7.21042 5.38542 8.46458 5.85833 9.30417C5.33958 9.075 4.8 8.75208 4.33333 8.29792C3.66667 7.65 3.125 6.71458 3.02083 5.33333H3.01875ZM11.6688 8.29792C11.2021 8.75208 10.6625 9.075 10.1438 9.30417C10.6167 8.46458 11.0354 7.21042 11.225 5.33333H12.9833C12.8771 6.71458 12.3354 7.65 11.6708 8.29792H11.6688Z" fill="#828282"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <path d="M30.9004 15L14.8004 5.76667C13.9292 5.2636 12.9403 5.00007 11.9343 5.00283C10.9283 5.00559 9.94099 5.27456 9.07257 5.7824C8.20415 6.29025 7.48561 7.01888 6.98993 7.8943C6.49425 8.76971 6.23909 9.76072 6.25036 10.7667V29.3C6.25036 30.8117 6.85089 32.2616 7.91985 33.3305C8.98881 34.3995 10.4386 35 11.9504 35C12.9511 34.9983 13.9338 34.7339 14.8004 34.2333L30.9004 25C31.7655 24.4993 32.4837 23.78 32.983 22.9141C33.4823 22.0482 33.7451 21.0662 33.7451 20.0667C33.7451 19.0671 33.4823 18.0852 32.983 17.2193C32.4837 16.3534 31.7655 15.634 30.9004 15.1333V15ZM29.2337 21.9833L13.1337 31.35C12.7728 31.5545 12.3651 31.662 11.9504 31.662C11.5356 31.662 11.1279 31.5545 10.767 31.35C10.4072 31.1422 10.1084 30.8434 9.90066 30.4836C9.69294 30.1237 9.58362 29.7155 9.58369 29.3V10.7C9.58362 10.2845 9.69294 9.8763 9.90066 9.51644C10.1084 9.15658 10.4072 8.85776 10.767 8.65C11.1294 8.4486 11.5359 8.33982 11.9504 8.33334C12.3646 8.34184 12.7706 8.45049 13.1337 8.65L29.2337 17.95C29.5937 18.1577 29.8926 18.4565 30.1005 18.8163C30.3083 19.1762 30.4177 19.5844 30.4177 20C30.4177 20.4156 30.3083 20.8238 30.1005 21.1837C29.8926 21.5436 29.5937 21.8423 29.2337 22.05V21.9833Z" fill="white"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M6.68652 6.68643C8.50879 4.86416 11.4531 4.85537 13.2871 6.65713L12.0801 7.86123C11.8779 8.06338 11.8193 8.36514 11.9277 8.62881C12.0361 8.89248 12.2939 9.0624 12.5781 9.0624H16.0791H16.3281C16.7178 9.0624 17.0312 8.74893 17.0312 8.35928V4.60928C17.0312 4.3251 16.8613 4.06729 16.5977 3.95889C16.334 3.85049 16.0322 3.90908 15.8301 4.11123L14.6113 5.32998C12.0449 2.7958 7.91113 2.80459 5.35938 5.35928C4.64453 6.07412 4.12891 6.91494 3.8125 7.81436C3.63965 8.30362 3.89746 8.83682 4.38379 9.00967C4.87012 9.18252 5.40625 8.92471 5.5791 8.43838C5.80469 7.79971 6.1709 7.19912 6.68652 6.68643ZM2.96875 11.6405V11.8632V11.8837V15.3905C2.96875 15.6747 3.13867 15.9325 3.40234 16.0409C3.66602 16.1493 3.96777 16.0907 4.16992 15.8886L5.38867 14.6698C7.95508 17.204 12.0889 17.1952 14.6406 14.6405C15.3555 13.9257 15.874 13.0849 16.1904 12.1884C16.3633 11.6991 16.1055 11.1659 15.6191 10.9931C15.1328 10.8202 14.5967 11.078 14.4238 11.5644C14.1982 12.203 13.832 12.8036 13.3164 13.3163C11.4941 15.1386 8.5498 15.1474 6.71582 13.3456L7.91992 12.1386C8.12207 11.9364 8.18066 11.6347 8.07227 11.371C7.96387 11.1073 7.70605 10.9374 7.42188 10.9374H3.91797H3.89746H3.67188C3.28223 10.9374 2.96875 11.2509 2.96875 11.6405Z" fill="#4F4F4F"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
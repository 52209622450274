<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path d="M7.00001 12.8333C10.2083 12.8333 12.8333 10.2083 12.8333 6.99992C12.8333 3.79159 10.2083 1.16659 7.00001 1.16659C3.79168 1.16659 1.16668 3.79158 1.16668 6.99992C1.16668 10.2083 3.79168 12.8333 7.00001 12.8333ZM7.00001 4.08325C7.35001 4.08325 7.58334 4.31659 7.58334 4.66659C7.58334 5.01658 7.35001 5.24992 7.00001 5.24992C6.65001 5.24992 6.41668 5.01658 6.41668 4.66659C6.41668 4.31658 6.65001 4.08325 7.00001 4.08325ZM6.41668 6.99992C6.41668 6.64992 6.65001 6.41658 7.00001 6.41658C7.35001 6.41658 7.58334 6.64992 7.58334 6.99992L7.58334 9.33325C7.58334 9.68325 7.35001 9.91658 7.00001 9.91658C6.65001 9.91658 6.41668 9.68325 6.41668 9.33325L6.41668 6.99992Z" fill="#2F80ED"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path d="M8.11278 13.2C11.075 13.2 13.4753 11.1684 13.4753 8.6625C13.4753 6.15656 11.075 4.125 8.11278 4.125C5.15052 4.125 2.75028 6.15656 2.75028 8.6625C2.75028 9.65766 3.12927 10.578 3.77122 11.3283C3.68099 11.5706 3.54692 11.7846 3.40513 11.9651C3.28138 12.1249 3.15505 12.2487 3.06224 12.3338C3.01583 12.375 2.97716 12.4085 2.95138 12.4291C2.93849 12.4395 2.92817 12.4472 2.92302 12.4498L2.91786 12.4549C2.77606 12.5606 2.71419 12.7463 2.77091 12.9138C2.82763 13.0814 2.98489 13.2 3.16278 13.2C3.72481 13.2 4.292 13.0556 4.7638 12.8777C5.00099 12.7875 5.2227 12.687 5.41606 12.5838C6.20755 12.9757 7.12794 13.2 8.11278 13.2ZM14.3003 8.6625C14.3003 11.5577 11.7454 13.7388 8.71864 13.9992C9.34513 15.9173 11.4231 17.325 13.8878 17.325C14.8726 17.325 15.793 17.1007 16.5871 16.7088C16.7804 16.812 16.9996 16.9125 17.2368 17.0027C17.7086 17.1806 18.2758 17.325 18.8378 17.325C19.0157 17.325 19.1755 17.209 19.2297 17.0388C19.2838 16.8687 19.2245 16.683 19.0801 16.5773L19.075 16.5722C19.0698 16.567 19.0595 16.5619 19.0466 16.5516C19.0208 16.5309 18.9822 16.5 18.9357 16.4562C18.8429 16.3711 18.7166 16.2473 18.5929 16.0875C18.4511 15.907 18.317 15.6905 18.2268 15.4507C18.8687 14.703 19.2477 13.7827 19.2477 12.7849C19.2477 10.3924 17.0589 8.43047 14.2822 8.26031C14.2925 8.3918 14.2977 8.52586 14.2977 8.65992L14.3003 8.6625Z" fill="#BDBDBD"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M2 7.24997C2 7.66481 2.33516 7.99997 2.75 7.99997C3.16484 7.99997 3.5 7.66481 3.5 7.24997C3.5 6.00778 4.50781 4.99997 5.75 4.99997H9.5V5.74997C9.5 6.05231 9.68281 6.32653 9.96406 6.44372C10.2453 6.56091 10.5664 6.49528 10.782 6.282L12.282 4.782C12.575 4.48903 12.575 4.01325 12.282 3.72028L10.782 2.22028C10.5664 2.00465 10.2453 1.94137 9.96406 2.05856C9.68281 2.17575 9.5 2.44762 9.5 2.74997V3.49997H5.75C3.67812 3.49997 2 5.17809 2 7.24997ZM14 8.74997C14 8.33512 13.6648 7.99997 13.25 7.99997C12.8352 7.99997 12.5 8.33512 12.5 8.74997C12.5 9.99216 11.4922 11 10.25 11H6.5V10.25C6.5 9.94762 6.31719 9.6734 6.03594 9.55622C5.75469 9.43903 5.43359 9.50465 5.21797 9.71794L3.71797 11.2179C3.425 11.5109 3.425 11.9867 3.71797 12.2797L5.21797 13.7797C5.43359 13.9953 5.75469 14.0586 6.03594 13.9414C6.31719 13.8242 6.5 13.5523 6.5 13.2476V12.5H10.25C12.3219 12.5 14 10.8218 14 8.74997Z" fill="#828282"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M8.4375 4.84375C8.4375 4.06738 9.06738 3.4375 9.84375 3.4375H10.7812C11.5576 3.4375 12.1875 4.06738 12.1875 4.84375V15.1562C12.1875 15.9326 11.5576 16.5625 10.7812 16.5625H9.84375C9.06738 16.5625 8.4375 15.9326 8.4375 15.1562V4.84375ZM3.75 10.4688C3.75 9.69238 4.37988 9.0625 5.15625 9.0625H6.09375C6.87012 9.0625 7.5 9.69238 7.5 10.4688V15.1562C7.5 15.9326 6.87012 16.5625 6.09375 16.5625H5.15625C4.37988 16.5625 3.75 15.9326 3.75 15.1562V10.4688ZM14.5312 5.3125H15.4688C16.2451 5.3125 16.875 5.94238 16.875 6.71875V15.1562C16.875 15.9326 16.2451 16.5625 15.4688 16.5625H14.5312C13.7549 16.5625 13.125 15.9326 13.125 15.1562V6.71875C13.125 5.94238 13.7549 5.3125 14.5312 5.3125Z" fill="#4F4F4F"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M11.25 21.25H16.25C17.9076 21.25 19.4973 20.5915 20.6694 19.4194C21.8415 18.2473 22.5 16.6576 22.5 15V13.75H23.75C24.7446 13.75 25.6984 13.3549 26.4017 12.6517C27.1049 11.9484 27.5 10.9946 27.5 10C27.5 9.00544 27.1049 8.05161 26.4017 7.34835C25.6984 6.64509 24.7446 6.25 23.75 6.25H22.5V5C22.5 4.66848 22.3683 4.35054 22.1339 4.11612C21.8995 3.8817 21.5815 3.75 21.25 3.75H6.25C5.91848 3.75 5.60054 3.8817 5.36612 4.11612C5.1317 4.35054 5 4.66848 5 5V15C5 16.6576 5.65848 18.2473 6.83058 19.4194C8.00269 20.5915 9.5924 21.25 11.25 21.25ZM22.5 8.75H23.75C24.0815 8.75 24.3995 8.8817 24.6339 9.11612C24.8683 9.35054 25 9.66848 25 10C25 10.3315 24.8683 10.6495 24.6339 10.8839C24.3995 11.1183 24.0815 11.25 23.75 11.25H22.5V8.75ZM7.5 6.25H20V15C20 15.9946 19.6049 16.9484 18.9017 17.6517C18.1984 18.3549 17.2446 18.75 16.25 18.75H11.25C10.2554 18.75 9.30161 18.3549 8.59835 17.6517C7.89509 16.9484 7.5 15.9946 7.5 15V6.25ZM26.25 23.75H3.75C3.41848 23.75 3.10054 23.8817 2.86612 24.1161C2.6317 24.3505 2.5 24.6685 2.5 25C2.5 25.3315 2.6317 25.6495 2.86612 25.8839C3.10054 26.1183 3.41848 26.25 3.75 26.25H26.25C26.5815 26.25 26.8995 26.1183 27.1339 25.8839C27.3683 25.6495 27.5 25.3315 27.5 25C27.5 24.6685 27.3683 24.3505 27.1339 24.1161C26.8995 23.8817 26.5815 23.75 26.25 23.75Z" fill="#333333"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if(props.fill){
                return props.fill
            }
            
            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }

@media screen and (min-width: 600px) {
//For Tablets
    .container {
        width: 100;
    }
}

@media screen and (min-width: 768px) {
//For Laptops
    .container {
        width: 738px;
    }
}

@media screen and (min-width: 992px) {
//For Large Laptops
    .container {
        width: 962px;
    }
}

@media screen and (min-width: 1280px) {
//For Big TV's (HD Screens) 
    .container {
        width: 1250px;
    }
}


@media screen and (min-width: 1920px) {
//For Projectors or Higher Resolution Screens (Full HD)
    .container {
        width: 1890px;
    }
}
@media screen and (min-width: 3840px) {
//For 4K Displays (Ultra HD)
    .container {
        width: 3810px;
    }
}
</style>
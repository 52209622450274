<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M8.87046 0.562437C8.70987 0.218726 8.37051 0 7.99782 0C7.62514 0 7.28881 0.218726 7.12519 0.562437L5.17691 4.69635L0.825864 5.35878C0.462267 5.41502 0.159269 5.67749 0.04716 6.03682C-0.0649493 6.39616 0.02595 6.79299 0.286528 7.05858L3.44377 10.2801L2.69839 14.8327C2.63779 15.2077 2.78929 15.5889 3.08926 15.8107C3.38923 16.0326 3.78615 16.0607 4.11339 15.8826L8.00085 13.7422L11.8883 15.8826C12.2156 16.0607 12.6125 16.0357 12.9124 15.8107C13.2124 15.5858 13.3639 15.2077 13.3033 14.8327L12.5549 10.2801L15.7121 7.05858C15.9727 6.79299 16.0667 6.39616 15.9515 6.03682C15.8364 5.67749 15.5364 5.41502 15.1728 5.35878L10.8187 4.69635L8.87046 0.562437Z" fill="#4F4F4F"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M20.2945 12.7945C20.734 12.3551 20.734 11.6414 20.2945 11.202L15.7945 6.70195C15.3551 6.2625 14.6414 6.2625 14.202 6.70195C13.7625 7.14141 13.7625 7.85508 14.202 8.29453L16.7824 10.875H9.375C8.75273 10.875 8.25 11.3777 8.25 12C8.25 12.6223 8.75273 13.125 9.375 13.125H16.7824L14.202 15.7055C13.7625 16.1449 13.7625 16.8586 14.202 17.298C14.6414 17.7375 15.3551 17.7375 15.7945 17.298L20.2945 12.798V12.7945ZM8.25 6.375C8.87227 6.375 9.375 5.87227 9.375 5.25C9.375 4.62773 8.87227 4.125 8.25 4.125H6C4.13672 4.125 2.625 5.63672 2.625 7.5V16.5C2.625 18.3633 4.13672 19.875 6 19.875H8.25C8.87227 19.875 9.375 19.3723 9.375 18.75C9.375 18.1277 8.87227 17.625 8.25 17.625H6C5.37773 17.625 4.875 17.1223 4.875 16.5V7.5C4.875 6.87773 5.37773 6.375 6 6.375H8.25Z" fill="#E0E0E0"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
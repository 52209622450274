<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M18.5402 9.00003L8.88021 3.46003C8.3575 3.15819 7.76421 3.00006 7.16061 3.00172C6.557 3.00338 5.96459 3.16476 5.44354 3.46947C4.92249 3.77417 4.49137 4.21135 4.19396 4.7366C3.89655 5.26185 3.74345 5.85646 3.75021 6.46003V17.58C3.75021 18.4871 4.11053 19.357 4.75191 19.9983C5.39328 20.6397 6.26317 21 7.17021 21C7.77065 20.999 8.3603 20.8404 8.88021 20.54L18.5402 15C19.0593 14.6996 19.4902 14.268 19.7898 13.7485C20.0894 13.2289 20.2471 12.6397 20.2471 12.04C20.2471 11.4403 20.0894 10.8511 19.7898 10.3316C19.4902 9.81206 19.0593 9.38044 18.5402 9.08003V9.00003ZM17.5402 13.19L7.88021 18.81C7.6637 18.9327 7.41908 18.9972 7.17021 18.9972C6.92135 18.9972 6.67673 18.9327 6.46021 18.81C6.24431 18.6854 6.06503 18.5061 5.9404 18.2902C5.81576 18.0743 5.75017 17.8293 5.75021 17.58V6.42003C5.75017 6.17072 5.81576 5.9258 5.9404 5.70989C6.06503 5.49397 6.24431 5.31468 6.46021 5.19003C6.67762 5.06918 6.92151 5.00392 7.17021 5.00003C7.41875 5.00513 7.66235 5.07032 7.88021 5.19003L17.5402 10.77C17.7562 10.8946 17.9356 11.0739 18.0603 11.2898C18.185 11.5057 18.2506 11.7507 18.2506 12C18.2506 12.2494 18.185 12.4943 18.0603 12.7102C17.9356 12.9262 17.7562 13.1054 17.5402 13.23V13.19Z" fill="#FAFAFA"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>